"use client";
import Image from "next/image";
import { useAtom } from "jotai";
import { accessTokenAtom, sidemenuAtom } from "@/store/global";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import React, { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import { useRouter } from "next/navigation";
import Popup from "@/components/Popup";
import { getLocalStorage } from "@/utils/storage";
import Link from "next/link";
import { saveCurrentUrlForRedirect } from "@/utils/saveCurrentUrl";
import { POPUP_TYPE } from "@/app/profile/ProfileClient";
import { logout } from "@/utils/token";
import Planet from "@/components/Planet";
// import { getDefaultQuestion } from "@/app/api/route";

export default function Home() {
  const [sidemenu, setSidemenu] = useState(false);
  const [authToken, setAuthToken] = useAtom(accessTokenAtom);
  const [firstEntry, setFirstEntry] = useState<boolean>(false); // 처음 진입 했는지 여부 (튜토리얼 오픈 여부)
  const [mainData, setMainData] = useState<Question>(); // 질문 및 해시태그등 메인 데이터
  const [slideIndex, setSlideIndex] = useState<number>(0); // 현재 슬라이드 인덱스
  const [popupType, setPopupType] = useState<string>(POPUP_TYPE.LOGOUT);
  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const [sideMenuPopupOpen, setSideMenuPopupOpen] = useState<boolean>(false);
  const router = useRouter();

  useEffect(() => {
    setSidemenu(false);
    const entry = getLocalStorage("firstEntry");
    if (entry) {
      callGetData();
    } else {
      router.push("/tutorial");
    }
  }, []);

  useEffect(() => {
    const entry = getLocalStorage("firstEntry");
    if (!!entry) {
      setFirstEntry(false);
    } else {
      setFirstEntry(true);
    }
  }, [authToken]);

  async function getDefaultQuestion() {
    try {
      const res = await fetch("/api/questions");
      if (!res.ok) {
        throw new Error("Failed to fetch data");
      }
      return await res.json();
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  const callGetData = async () => {
    const data = await getDefaultQuestion();
    setMainData(data?.data);
  };

  const convertNewlinesToBreaks = (text: string) => {
    return text?.split("\n").map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  };

  const popupCallback = (type: string) => {
    if (type === POPUP_TYPE.LOGOUT) {
      logout();
      setSidemenu(false);
      setPopupOpen(false);
      setSideMenuPopupOpen(false);
      setAuthToken(null);
      router.refresh();
    } else if (type === POPUP_TYPE.MEMBER_OUT) {
      //TODO: 회원탈퇴
    }
  };

  return (
    <main className="relative mx-auto flex min-h-screen max-w-3xl flex-col items-center justify-between bg-black">
      <Image
        src="/images/main/bg_main.png"
        alt="mainBgImg"
        fill
        className="object-cover"
      />
      {/*navbar*/}
      <div className="fixed z-20 mx-auto flex h-[56px] w-full max-w-3xl items-center">
        {/*심볼 아이콘*/}
        <Image
          src="/images/main/icon_navbar_symbol.svg"
          alt="symbol"
          className="absolute left-4"
          width={46}
          height={32}
        />

        {/*햄버거 메뉴*/}
        <Image
          src="/images/main/btn_menu.svg"
          alt="menu"
          className="absolute right-4 cursor-pointer"
          width={36}
          height={36}
          onClick={() => {
            setSidemenu(true);
          }}
        />

        {/* 사이드 메뉴 */}
        <div
          className={`fixed right-0 top-0 z-30 h-full w-[260px] bg-white transition-transform duration-500 ease-in-out ${
            sidemenu ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <div className="flex h-[56px] items-center">
            <Image
              src="/images/main/btn_close.svg"
              alt="closeBtn"
              className="absolute right-4 cursor-pointer"
              width={36}
              height={36}
              onClick={() => {
                setSidemenu(false);
              }}
            />
          </div>
          {authToken ? (
            <div className="">
              <span className="flex h-[30px] items-end px-6 font-GLight text-[#AEAEAE]">
                {"My"}
              </span>
              <ul className="flex flex-col font-GBold text-[17px] text-[#121212]">
                <li className="flex h-[48px] w-full items-center">
                  <Link
                    href={"/profile"}
                    className="flex h-[48px] w-full items-center text-left"
                  >
                    <span className="px-6">{"프로필"}</span>
                  </Link>
                </li>
              </ul>
              <span className="flex h-[30px] items-end px-6 font-GLight text-[#AEAEAE]">
                {"Home"}
              </span>
              <div className="px-6">
                <ul className="flex flex-col font-GBold text-[17px] text-[#121212]">
                  <li>
                    <a
                      href={
                        "https://nogamsung.notion.site/0d33338424154e00a76bc8c28492731f"
                      }
                      target={"_blank"}
                      className="h-[48px] flex items-center w-full text-left"
                    >
                      {"서비스 소개"}
                    </a>
                  </li>
                  <li className="h-[48px] w-full text-left content-center">
                    <a
                      href="https://www.instagram.com/woomul.team/?img_index=1"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      의견 보내기
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          ) : (
            <>
              <div
                className="flex h-[88px] cursor-pointer flex-row items-center p-6"
                onClick={() => {
                  saveCurrentUrlForRedirect();
                  router.push("/login");
                }}
              >
                <Image
                  src="/images/main/icon_login.png"
                  alt="login_icon"
                  className=""
                  width={40}
                  height={40}
                />
                <span className="ml-3 font-GBold text-[17px] text-[#5F5F5F]">
                  {"로그인하세요."}
                </span>
              </div>
              <div className="px-6">
                <span className="flex h-[30px] items-end font-GLight text-[#AEAEAE]">
                  {"Home"}
                </span>
                <ul className="flex flex-col font-GBold text-[17px] text-[#121212]">
                  <li className="h-[48px] w-full text-left content-center">
                    <a
                      href={
                        "https://nogamsung.notion.site/0d33338424154e00a76bc8c28492731f"
                      }
                      target={"_blank"}
                    >
                      {"서비스 소개"}
                    </a>
                  </li>
                  <li className="h-[48px] w-full text-left content-center">
                    <a
                      href="https://www.instagram.com/woomul.team/?img_index=1"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      의견 보내기
                    </a>
                  </li>
                </ul>
              </div>
            </>
          )}
        </div>
      </div>

      {/*메인컨텐츠*/}
      <div className="relative mt-[56px] flex h-[calc(100dvh_-_56px)] w-full items-center text-white">
        <div className="flex w-full items-center justify-between">
          <div
            className={`swiper-button-prev-custom absolute z-20 ml-2 ${slideIndex === 0 && "hidden"}`}
          >
            <Image
              src="/images/main/icon_l_button.svg"
              alt="prev"
              width={24}
              height={24}
            />
          </div>

          <Swiper
            className="w-full"
            modules={[Navigation]}
            spaceBetween={50}
            slidesPerView={1}
            navigation={{
              nextEl: ".swiper-button-next-custom",
              prevEl: ".swiper-button-prev-custom",
            }}
            onSlideChange={(swiper) => {
              setSlideIndex(swiper.realIndex);
            }}
          >
            <SwiperSlide className="relative flex items-center justify-center text-white">
              <div className="relative">
                {/*<Image*/}
                {/*  src={"/images/temp/bg_circle.png"}*/}
                {/*  alt="bgImg"*/}
                {/*  width={280}*/}
                {/*  height={280}*/}
                {/*  className="mx-auto"*/}
                {/*/>*/}
                {
                  <div className="mx-auto flex justify-center">
                    <Planet
                      innerColor={"#FFACA8"}
                      outerColor={"#1AE7D8"}
                      size={360}
                    />
                  </div>
                }
                <div className="absolute inset-0 mx-auto flex w-[280px] flex-col items-start justify-center p-4">
                  <div className="mb-5 flex gap-2 font-GLight text-[17px]">
                    {mainData?.categories?.map((v, i) => (
                      <span key={i}>#{v.categoryName}</span>
                    ))}
                  </div>
                  <div className="text-start font-GBold text-2xl">
                    {convertNewlinesToBreaks(mainData?.questionText || "")}
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide
              className="relative flex items-center justify-center text-white"
              key={99}
            >
              <div className="relative">
                <div className="mx-auto flex justify-center">
                  <Planet
                    innerColor={"#00FFFF"}
                    outerColor={"#4FB5FF"}
                    size={360}
                  />
                </div>
                <div className="absolute inset-0 mx-auto flex w-[280px] flex-col items-start justify-center p-4">
                  <div className="text-start font-GBold text-2xl">
                    {convertNewlinesToBreaks(
                      "친구에게 궁금한 질문을\n직접 적어보세요.",
                    )}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <div
            // className={`swiper-button-next-custom absolute right-0 z-20 mr-2 ${(!mainData || mainData?.length === slideIndex) && "hidden"}`}
            className={`swiper-button-next-custom absolute right-0 z-20 mr-2 ${slideIndex === 1 && "hidden"}`}
          >
            <Image
              src="/images/main/icon_r_button.svg"
              alt="next"
              width={24}
              height={24}
            />
          </div>
        </div>

        <div className="absolute bottom-5 z-10 w-full px-10">
          <button
            onClick={() => {
              if (authToken) {
                if (slideIndex < 1) {
                  router.push(`/question/${mainData?.questionId}/answer`);
                } else {
                  router.push("/question");
                }
              } else {
                setPopupOpen(true);
              }
            }}
            className="mb-10 h-[54px] w-full rounded-full bg-white text-center font-GBold text-[19px] text-[#121212]"
          >
            {slideIndex < 1 ? "친구와 함께 답변하기" : "질문 적으러 가기"}
          </button>
        </div>
      </div>

      {popupOpen && (
        <Popup className="max-w-[360px] p-[24px]" innerClass="rounded-[16px]">
          <div className="flex w-full flex-col items-center">
            <p className="mt-6 px-6 font-GLight text-[17px] text-[#3D3D3D]">
              {"3초면 충분해요"}
            </p>
            <p className="px-6 pt-1 font-GBold text-[17px] text-[#1F1F1F]">
              {"친구에게 질문을 보내려면\n로그인을 먼저 해주세요."
                .split("\n")
                .map((line, index) => (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                ))}
            </p>
            <hr className="mt-5 w-full text-gray-200" />
            <div className="flex w-full flex-row">
              <button
                className="flex h-[56px] w-full items-center justify-center font-GBold text-[#949494]"
                onClick={() => {
                  saveCurrentUrlForRedirect();
                  router.push("/login");
                }}
              >
                {"확인"}
              </button>
            </div>
          </div>
        </Popup>
      )}

      {sideMenuPopupOpen && (
        <Popup className="max-w-[360px] p-[24px]" innerClass="rounded-[16px]">
          <div className="flex w-full flex-col items-start">
            <p className="px-6 pt-6 font-GBold text-[17px] text-[#1F1F1F]">
              {(popupType === POPUP_TYPE.MEMBER_OUT
                ? "정말 탈퇴하시겠어요?"
                : "로그아웃 할까요?"
              )
                .split("\n")
                .map((line, index) => (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                ))}
            </p>
            {popupType === POPUP_TYPE.MEMBER_OUT && (
              <p className="mt-2 px-6 font-GLight text-[17px] text-[#3D3D3D]">
                {"지금까지 작성한 질문과 답변은 모두 삭제돼요."}
              </p>
            )}
            <hr className="mt-5 w-full text-gray-200" />
            <div className="flex w-full flex-row">
              <>
                <button
                  className="flex h-[56px] w-full items-center justify-center font-GBold text-[#949494]"
                  onClick={() => {
                    setSideMenuPopupOpen(false);
                  }}
                >
                  {"취소"}
                </button>
                <div className="h-[56px] w-[1px] bg-gray-200"></div>
              </>
              <button
                className="flex h-[56px] w-full items-center justify-center font-GBold text-black"
                onClick={() => {
                  setAuthToken(null);
                  popupCallback(popupType);
                }}
              >
                {popupType === POPUP_TYPE.MEMBER_OUT ? "탈퇴" : "로그아웃"}
              </button>
            </div>
          </div>
        </Popup>
      )}
    </main>
  );
}
